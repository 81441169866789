import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { filtersOrder, getFilterOptionName } from "../lib/helpers";
import FilterSelect from "./common/FilterSelect";

const ClearFiltersBtn = ({ onClearFilters, className }) => {
  const { t } = useTranslation();
  return (
    <button
      className={`
        border
        rounded-full
        border-white
        hover:opacity-75
        text-red-200
        leading-none
        h-6
        mr-6
        py-0
        px-3
        bg-red-300
        lg:mt-2
        lg:ml-2
        ${className}`}
      onClick={onClearFilters}
    >
      {t("actions.clear")}
    </button>
  );
};

const getSidebarClasses = (expandable, sidebarOpened, className) => {
  const sharedClasses = `sidebar lg:shadow-md ${
    sidebarOpened ? "expanded" : "collapsed"
  } ${className}`;
  return expandable
    ? `${sharedClasses} expandable p-0 w-80 overflow-visible`
    : `${sharedClasses} w-full lg:p-0 lg:w-80 lg:relative overflow-hidden lg:overflow-visible`;
};

const Sidebar = ({
  className,
  currentFilters,
  expandable,
  filters,
  isLoading,
  gridSidebar,
  onChangeFilter,
  onClearFilters,
}) => {
  const [sidebarOpened, setSidebarOpened] = useState(gridSidebar);
  const { t } = useTranslation();

  const buttonClasses = `uppercase bg-transparent py-1 px-2 block sidebar-toggle b-0 text-white red-btn ${
    expandable ? "expandable" : "lg:hidden"
  }
  ${sidebarOpened ? "expanded" : "collapsed"}
  ${gridSidebar ? "grid-sidebar" : ""}
`;

  const sidebarClasses = getSidebarClasses(expandable, sidebarOpened, className);

  return (
    <aside className={sidebarClasses}>
      <div className="height-of-sidebar h-full lg:h-0 overflow-visible">
        <div className="flex flex-col sidebar-content">
          <div className="flex-grow flex flex-col">
            {!isLoading && (
              <React.Fragment>
                <div className="flex flex-row justify-between items-center">
                  <button
                    className={buttonClasses}
                    onClick={() => setSidebarOpened((current) => !current)}
                  >
                    {t("labels.filters")}
                  </button>
                  <div className={`flex flex-row align-center ${expandable ? "justify-end" : ""}`}>
                    {!_.isEmpty(currentFilters) && (
                      <ClearFiltersBtn onClearFilters={onClearFilters} className="block z-10" />
                    )}
                  </div>
                </div>
                <div className="flex flex-col flex-grow py-3 pl-3 pr-1">
                  <div className="sidebar__filters-container pr-2">
                    {_.filter(filtersOrder, (filterName) => filters && filters[filterName]).map(
                      (filterName) => {
                        const options = filters ? filters[filterName] : [];
                        return (
                          <FilterSelect
                            key={filterName}
                            label={t(`filters.labels.${filterName}`)}
                            selected={currentFilters[filterName] || "any"}
                            options={_.map(
                              [{ name: "any", count: 0 }].concat(
                                _.filter(options, (option) => option.name !== "all")
                              ),
                              (option) => ({
                                text: getFilterOptionName(t, filterName, option),
                                value: option.name,
                              })
                            )}
                            onChange={(value) => onChangeFilter(filterName, value)}
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>

          {/* TODO need to fix this someday
        {gridSidebar && (
          <HeatMapSwitcher colorsCount={colorsCount} setHeatMap={setHeatMap} heatMap={heatMap} />
        )} */}
          <button
            className="close-filter-sidebar block lg:hidden"
            onClick={() => setSidebarOpened(false)}
          />
        </div>
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  expandable: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  currentFilters: PropTypes.object,
  onClearFilters: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
  gridSidebar: PropTypes.bool,
  setHeatMap: PropTypes.func,
  colorsCount: PropTypes.number,
  filters: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      })
    )
  ),
};

Sidebar.defaultProps = {
  expandable: false,
  className: "",
  gridSidebar: false,
  setHeatMap: null,
  colorsCount: null,
};

export default Sidebar;
