import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { guidelineShape } from "../../lib/data_shapes";
import { getRecommendationText, getRecommendationType } from "../../lib/helpers";
import Box from "../common/Box";
import Certainty from "../common/Certainty";
import Intents from "../common/Intents";
import RecommendationIcon from "../common/RecommendationIcon";
import Scores from "../common/Scores";
import Transformed from "../common/Transformed";
import TypeHeader, { RECOMMENDATION_FORMALITIES } from "../common/TypeHeader";
import GuidelineHeader from "./GuidelineHeader";

const RecommendationHeader = ({
  guideline,
  gradeCertaintyOfEvidence,
  informal,
  intents,
  recommendation,
  recommendationFormality,
  gps,
  transformed,
  onRequestAdolopment,
  authors,
  publicationDate,
}) => {
  const { t } = useTranslation();
  const { strength: gradeStrength, direction: recommendationDirection } = recommendation ?? {};

  const recommendationType = useMemo(() => {
    return getRecommendationType(recommendationDirection, gradeStrength);
  }, [recommendationDirection, gradeStrength]);

  const recommendationText = useMemo(() => {
    return getRecommendationText(t, gradeStrength);
  }, [t, gradeStrength]);

  if (!recommendation) return null;

  return (
    <div>
      <div className="flex flex-row items-center">
        <div className="flex-grow">
          <TypeHeader
            className="tailwind-hidden md:flex mb-3 mt-1"
            recommendationFormality={recommendationFormality}
            type="recommendation"
          />
        </div>
      </div>
      <Box className="bg-red-300">
        {!gps && <GuidelineHeader guideline={guideline} />}
        <div className="flex flex-col md:flex-row">
          <div className="flex-grow p-5 roboto link-finder">
            {!gps && <Intents intents={intents} />}
            <div className="mr-6" dangerouslySetInnerHTML={{ __html: recommendation.value }} />
          </div>
          <div className={`flex flex-col mt-3 md:mt-0 p-5 bg-gray-1200`}>
            <div className="strength-certainty-column mb-4">
              {gradeCertaintyOfEvidence && (
                <div className="bordered-info-box">
                  <div className="flex flex-row items-center">
                    <div
                      className="font-semibold mr-4"
                      data-tip={t("tooltips.certainty_of_evidence")}
                    >
                      <div>{t("recommendation.certainty_of_evidence")}</div>
                    </div>
                    <Transformed transformed={transformed} />
                  </div>
                  <Certainty value={gradeCertaintyOfEvidence} />
                </div>
              )}
              {recommendation.strength && (
                <div className="bordered-info-box" data-tip={t("tooltips.strength")}>
                  <div className="font-semibold">{t("recommendation.recommendation_strength")}</div>
                  <div>
                    <RecommendationIcon
                      textClasses="font-normal"
                      recommendationText={recommendationText}
                      recommendationType={recommendationType}
                    />
                  </div>
                </div>
              )}
              {authors && (
                <div className="bordered-info-box">
                  <p className="font-semibold">{t("recommendation.authors")}</p>
                  <p dangerouslySetInnerHTML={{ __html: authors }} />
                </div>
              )}
              {publicationDate.length !== 0 && (
                <div className="bordered-info-box">
                  <p className="font-semibold">{t("labels.pubDate")}</p>
                  <p>{publicationDate}</p>
                </div>
              )}
              <Scores agreeScore={guideline.agreeScore} />
            </div>
            {!informal && !gps && (
              <button className="btn-gradient rounded-full py-1 mt-3" onClick={onRequestAdolopment}>
                {t("labels.requestAdolopment")}
              </button>
            )}
          </div>
        </div>
      </Box>
    </div>
  );
};

RecommendationHeader.propTypes = {
  recommendation: PropTypes.object.isRequired,
  guideline: guidelineShape.isRequired,
  gradeStrength: PropTypes.string,
  informal: PropTypes.bool,
  gps: PropTypes.bool,
  authors: PropTypes.string,
  publicationDate: PropTypes.string,
  transformed: PropTypes.bool,
  intents: PropTypes.arrayOf(PropTypes.string),
  recommendationFormality: PropTypes.oneOf(RECOMMENDATION_FORMALITIES).isRequired,
};

RecommendationHeader.defaultProps = {
  gradeStrength: "",
  gps: false,
  informal: false,
  intents: [],
  transformed: false,
};

export default RecommendationHeader;
