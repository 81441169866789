import _ from "lodash";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import epLogo from "../../assets/logos/ep_logo_white.svg";
import gradeProLogo from "../../assets/logos/gradepro_white.svg";
import mcMasterLogo from "../../assets/logos/mcmaster-logo.svg";
import {
  twitterLink,
  glossaryLink,
  linkedIn,
  facebook,
  contactMail,
  getItGlossaryLink,
} from "../../lib/constants";
import { useHandleClickOutside } from "../../lib/custom_hooks";
import TwitterImage from "../../assets/twitter_canpcc.svg";
import FacebookImage from "../../assets/facebook_canpcc.svg";
import LinkedInImage from "../../assets/linkedin_canpcc.svg";
import ExternalLink from "./ExternalLink";

const HamburgerMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useHandleClickOutside(menuRef, () => setMenuOpen(false));

  const goTo = (page: string) => () => {
    history.push(page);
    setMenuOpen(false);
  };

  return (
    <div ref={menuRef} className="h-6 relative ml-auto mt-1 self-start justify-end hamburger-menu">
      <button
        className={`open-menu block ${menuOpen ? "open" : "closed"}`}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      />

      <div className={`menu-container ${menuOpen ? "open" : "closed"}`}>
        <div className="flex flex-col h-full">
          <div className="flex flex-col flex-grow">
            <div className="py-5 mx-5 glossary-links">
              <ExternalLink
                className="text-left block py-2 font-semibold hover:opacity-50"
                url={t("links.about")}
              >
                {t(`app.aboutCovid19`)}
              </ExternalLink>
              <ExternalLink
                className="text-left block py-2 font-semibold hover:opacity-50"
                url={t("links.approach")}
              >
                {t(`app.approach`)}
              </ExternalLink>
              <button
                className="text-left block py-2 font-semibold hover:opacity-50"
                onClick={goTo("/glossary")}
              >
                {t("labels.glossary")}
              </button>
              <ExternalLink
                className="text-left block py-2 font-semibold hover:opacity-50"
                url={glossaryLink}
              >
                {t("labels.grade_handbook_glossary")}
              </ExternalLink>
              <a
                className="block py-2 font-semibold hover:opacity-50"
                href={getItGlossaryLink}
                target="blank"
                rel="noopener noreferrer"
              >
                {t("labels.get_it_glossary")}
              </a>
            </div>
            <div className="footer__socials social-link--footer my-4 border-t border-b py-3 border-white">
              {!_.isEmpty(twitterLink) && (
                <div>
                  <a
                    className="social-link social-link--footer"
                    href={twitterLink}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    <svg className="twitter-link">
                      <use xlinkHref={`${TwitterImage}#twitter`} />
                    </svg>
                  </a>
                </div>
              )}
              {!_.isEmpty(facebook) && (
                <div>
                  <a
                    className="social-link social-link--footer"
                    href={facebook}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    <svg className="facebook-link">
                      <use xlinkHref={`${FacebookImage}#facebook`} />
                    </svg>
                  </a>
                </div>
              )}
              {!_.isEmpty(linkedIn) && (
                <div>
                  <a
                    className="social-link social-link--footer"
                    href={linkedIn}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    <svg className="linkedin-link">
                      <use xlinkHref={`${LinkedInImage}#linkedin`} />
                    </svg>
                  </a>
                </div>
              )}
            </div>
            <div className="logos flex flex-col flex-grow">
              <a
                className="block mb-6 flex justify-end mr-6"
                href="https://www.mcmaster.ca/"
                target="blank"
                rel="noopener noreferrer"
              >
                <img src={mcMasterLogo} alt="McMaster" />
              </a>
              <a
                className="block mb-12 flex justify-end mr-6"
                href="https://evidenceprime.com/"
                target="blank"
                rel="noopener noreferrer"
              >
                <img src={epLogo} alt="EvidencePrime" />
              </a>
              <a
                className="block mb-6 flex justify-end mr-6"
                href="https://gradepro.org/"
                target="blank"
                rel="noopener noreferrer"
              >
                <img src={gradeProLogo} alt="GRADEpro" />
              </a>

              <a
                className="block py-2 font-semibold hover:opacity-50 pr-4 text-right"
                href={`mailto:${contactMail}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("labels.contact_us")}
              </a>
            </div>
          </div>

          <div className="text-center sm:text-left">
            <button className="close-menu-arrow" onClick={() => setMenuOpen(false)} />
          </div>
        </div>
      </div>
    </div>
  );
};

HamburgerMenu.propTypes = {};

export default HamburgerMenu;
