import _ from "lodash";
import {
  DependencyList,
  RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { showExpirationBanner } from "./constants";

export const useHandleClickOutside = (
  contentRef: RefObject<HTMLDivElement> | null,
  onClose: () => void,
  closeOnClickOutside?: boolean
) => {
  const handleClickOutside = (evt: MouseEvent) => {
    if (
      contentRef?.current &&
      !contentRef.current.contains(evt.target as Node) &&
      _.isFunction(onClose) &&
      closeOnClickOutside
    ) {
      onClose();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export const useSearchQuery = () => {
  return new URLSearchParams(useLocation().search).get("searchQuery") || "";
};

export const useShowBanner = (): boolean => {
  const location = useLocation();
  const activeRoute = useMemo(
    () => _.first(_.split(_.trimStart(location.pathname, "/"), "/")),
    [location.pathname]
  );

  return activeRoute !== "grid" && showExpirationBanner;
};

// https://gist.github.com/Danziger/336e75b6675223ad805a88c2dfdcfd4a#file-interval-hook-ts
export function useInterval(
  callback: React.EffectCallback,
  delay: number | null
): React.MutableRefObject<number | null> {
  const intervalRef = useRef<number | null>(null);
  const callbackRef = useRef(callback);

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setInterval ticks again, it
  // will still call your old callback.
  //
  // If you add `callback` to useEffect's deps, it will work fine but the
  // interval will be reset.

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval:

  useEffect(() => {
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(() => callbackRef.current(), delay);

      // Clear interval if the components is unmounted or the delay changes:
      return () => window.clearInterval(intervalRef.current || 0);
    }
  }, [delay]);

  // In case you want to manually clear the interval from the consuming component...:
  return intervalRef;
}

export const useDebounceValue = (value: string, time = 150) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, time);
    return () => {
      clearTimeout(timeout);
    };
  }, [value, time]);
  return debouncedValue;
};

export const useResizeContainer = (containerId: string, deps: DependencyList = []) => {
  useLayoutEffect(() => {
    const recalculateHeights = () => {
      // get header dimensions
      const headerHeight = document
        .getElementsByTagName("header")[0]
        .getBoundingClientRect().height;

      const searchHeight =
        document.getElementById("search-and-tags-container")?.getBoundingClientRect().height || 0;

      const sidebarHeight = _.clamp(
        document.querySelector(".height-of-sidebar")?.getBoundingClientRect().height || 0,
        0,
        50
      ); // We want only add sidebar height when it is visible but not expanded, otherwise the
      // list will be very short

      const bodyHeight = document.body.getBoundingClientRect().height;

      const containerToResize = document.getElementById(containerId);
      if (containerToResize) {
        containerToResize.style.height =
          (bodyHeight - (searchHeight + 10) - sidebarHeight - headerHeight).toString() + "px";
      }
    };
    recalculateHeights();
    window.addEventListener("resize", recalculateHeights);

    return () => {
      window.removeEventListener("resize", recalculateHeights);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId, ...deps]);
};
