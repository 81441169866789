import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Header from "./components/Header";
import LargeSpinner from "./components/common/LargeSpinner";
import PageLayout from "./components/layouts/PageLayout";
import About from "./components/pages/About";
import Experts from "./components/pages/Experts";
import Glossary from "./components/pages/Glossary";
import Grid from "./components/pages/Grid";
import GuidelinePage from "./components/pages/GuidelinePage";
import GuidelinesOnChangeOfCare from "./components/pages/GuidelinesOnChangeOfCare";
import MainPage from "./components/pages/MainPage";
import OurPublications from "./components/pages/OurPublications";
import PlainLanguageRecommendations from "./components/pages/PlainLanguageRecommendations";
import RecommendationPage from "./components/pages/RecommendationPage";
import RecommendationsPage from "./components/pages/RecommendationsPage";
import SearchPage from "./components/pages/SearchPage";
import { useShowBanner } from "./lib/custom_hooks";
import ReactGA from "react-ga4";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("G-YZNJPKVELH");
}

export const AppContent = () => {
  const { t } = useTranslation("");
  const withBanner = useShowBanner();

  return (
    <div className="font-transat min-h-screen overflow-hidden w-full m-0 p-0 bg-right">
      <div
        className={`min-h-screen overflow-hidden m-0 p-0 bg-left ${
          withBanner ? "with-banner" : ""
        }`}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("app.title")}</title>
          <meta name="description" content={t("app.aboutShort")} />
          <meta name="keywords" content={t("app.keywords")} />
          <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
          <script
            type="text/javascript"
            //@ts-ignore
            charset="UTF-8"
            src="//cdn.cookie-script.com/s/dc924625c6b1ef01eaf8c7ed870dbc24.js"
          ></script>
        </Helmet>
        <Switch>
          <>
            <Header />
            <Route exact path={["/", "/home"]}>
              <MainPage />
            </Route>
            <Route exact path="/recommendations">
              <RecommendationsPage />
            </Route>
            <Route exact path="/grid">
              <Grid />
            </Route>
            <Route exact path="/glossary">
              <PageLayout pageTitle={t("labels.glossary")}>
                <Glossary />
              </PageLayout>
            </Route>
            <Route path="/guideline/:id">
              <PageLayout className="bg-gray-100" pageTitle={t("labels.guideline")}>
                <GuidelinePage />
              </PageLayout>
            </Route>
            <Route path="/search">
              <SearchPage />
            </Route>
            <Route path="/recommendation/:id">
              <RecommendationPage />
            </Route>
            <Route exact path="/about">
              <PageLayout className="bg-gray-opacity30">
                <About />
              </PageLayout>
            </Route>
            <Route exact path="/guidelines-on-change-of-care">
              <PageLayout className="bg-gray-opacity30">
                <GuidelinesOnChangeOfCare />
              </PageLayout>
            </Route>
            <Route exact path="/plain-language-recommendations">
              <PageLayout
                className="bg-gray-opacity30"
                pageTitle={t("labels.plainLanguageSummary")}
              >
                <PlainLanguageRecommendations />
              </PageLayout>
            </Route>
            <Route exact path="/our-publications">
              <PageLayout className="bg-gray-opacity30">
                <OurPublications />
              </PageLayout>
            </Route>
            <Route exact path="/experts-bureau">
              <PageLayout className="bg-gray-opacity30">
                <Experts />
              </PageLayout>
            </Route>
          </>
        </Switch>
      </div>
    </div>
  );
};

function App() {
  const { ready } = useTranslation("");

  // wait until translations are loaded
  return ready ? (
    <Router>
      <AppContent />
    </Router>
  ) : (
    <LargeSpinner />
  );
}

export default App;
