import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetApi } from "../../lib/api";
import { parseTableWithHeaders } from "../../lib/helpers";
import Box from "../common/Box";
import LargeSpinner from "../common/LargeSpinner";
import SimpleSearch from "../common/SimpleSearch";
import { UrlRow } from "../common/URLRow";
import ReactTooltip from "react-tooltip";

const RecommendationStrengthIcon = ({ strength }: { strength: string }) => {
  const { t } = useTranslation();

  const strengthKb = _.kebabCase(strength);
  return (
    <div data-tip={t("tooltips.strength")}>
      <div className="flex flex-row items-center leading-none lowercase">
        <div className="mr-1">{t(`recommendation.strength.${strengthKb}`)}</div>
        <div className={`recommendation-icon recommendation-icon--small ${strengthKb}`} />
      </div>
    </div>
  );
};

type TData = {
  body: string;
};

type TRow = {
  title: string;
  pubDate: string;
  urlToPlainLanguageRecommendation: string;
  urlToRecMapRecommendation: string;
};

const languageOptions: { [key: number]: string } = {
  0: "english",
  1: "french",
};

const TableRow = ({
  currentLng,
  headers,
  row,
}: {
  currentLng: string;
  headers: string[];
  row: string[];
}) => {
  const getValue = (value: string, idx: number) => {
    switch (idx) {
      case 0: // Title
        return <h3 className="font-semibold text-base mb-2">{value}</h3>;
      case 1: // Strength
        return !_.isEmpty(_.trim(value)) ? (
          <div className="font-semibold text-sm mb-2 flex flex-row items-center w-80">
            <div className="mr-1">
              {headers[idx]}
              {": "}
            </div>
            <RecommendationStrengthIcon strength={value} />
          </div>
        ) : null;
      case 2: // Publication date
        return <h3 className="font-semibold text-sm mb-2">{value}</h3>;
      case 3:
      case 4: // url
        return !_.isEmpty(value?.trim()) ? (
          <UrlRow url={value} label={headers[idx]} onlyLabel />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <div className={`mb-5 text-xs ${currentLng}`}>
      {_.map(row, (value, idx) => {
        return <div key={idx}>{getValue(value, idx)}</div>;
      })}
      <div className="gradient-separator" />
    </div>
  );
};

const PlainLanguageRecommendationsTable = ({
  data,
  searchQuery,
  plrLng,
}: {
  data: TData;
  searchQuery: string;
  plrLng: number;
}) => {
  const [headers, body] = useMemo(() => parseTableWithHeaders(data.body), [data]);

  const filteredRows = _.filter(body, (row: TRow) => {
    const sq = searchQuery.toLowerCase();
    return _.some(row, (cell) => cell.toLowerCase().indexOf(sq) !== -1);
  });
  const grouppedByTopic = _.groupBy(filteredRows, _.last); // group by topic name from last column

  return _.isEmpty(grouppedByTopic) ? null : (
    <div>
      <div className="plr-list">
        {_.map(grouppedByTopic, (table, title) => {
          return (
            <div className="mb-4">
              <h1 className="text-xl mb-4 text-red-200">{title}</h1>
              {_.map(table, (row: string[], index: number) => (
                <TableRow
                  currentLng={languageOptions[plrLng]}
                  headers={headers}
                  row={row}
                  key={index}
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PlainLanguageRecommendations = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const [{ data, isLoading }] = useGetApi(`/plain-language-recommendations`);
  return (
    <div className="pb-6 roboto-condensed">
      <div className="w-full max-w-screen-lg relative mx-auto pb-6">
        <h1 className="tailwind-hidden md:block pl-6 pt-2 text-2xl text-red-200">
          {t("labels.plainLanguageRecommendations")}
        </h1>
        <Box className="p-5 bg-white mt-5">
          <div className="p-4">
            {isLoading ? (
              <LargeSpinner />
            ) : _.isEmpty(data) ? null : (
              <PlainLanguageRecommendationsList data={data} />
            )}
          </div>
        </Box>
      </div>
    </div>
  );
};

const PlainLanguageRecommendationsList = ({ data }: { data: TData[] }) => {
  const { t, i18n } = useTranslation();
  const [plrLng, setPlrLng] = useState(i18n.language === "fr" ? 1 : 0);

  const [searchQuery, setSearchQuery] = useState("");

  const changePlrLng = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPlrLng(parseInt(e.target.value));
  };

  const list = data[plrLng];

  return (
    <div>
      <div className="mb-12  w-full flex flex-row items-center">
        <div className="flex-grow">
          <div className="additional-guidance-search-container">
            <SimpleSearch searchQuery={searchQuery} onSearch={setSearchQuery} />
          </div>
        </div>
        <div>
          <select className="language-selector" onChange={changePlrLng} value={plrLng}>
            {_.map(languageOptions, (opt, index) => (
              <option key={opt} value={index}>
                {t(`languages.${opt}`)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <PlainLanguageRecommendationsTable data={list} searchQuery={searchQuery} plrLng={plrLng} />
      <ReactTooltip
        className="tooltip-container"
        type="dark"
        effect="solid"
        backgroundColor="#031B49"
        clickable
        delayHide={300}
      />
    </div>
  );
};

export default PlainLanguageRecommendations;
