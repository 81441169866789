import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";

type SearchProps = {
  title: ReactNode;
};

const SubHeader = ({ title }: SearchProps) => {
  const history = useHistory();
  return (
    <div className="bg-red-200 text-white search-header-bar shadow flex flex-row items-center px-4 py-2">
      <button
        className="mobile-back-link block lg:hidden"
        onClick={() => (history.length > 2 ? history.goBack() : history.push("/grid"))}
      />
      {title}
    </div>
  );
};

export default SubHeader;
