import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Search from "../common/Search";
import { useSearchQuery } from "../../lib/custom_hooks";
import SubHeader from "../common/SubHeader";
import { useHistory } from "react-router-dom";

const SearchPage = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const initialSearchQuery = useSearchQuery();

  const onSearch = useCallback(
    (query) => {
      history.push(
        `/recommendations?${new URLSearchParams(
          `lng=${i18n.language}&searchQuery=${encodeURIComponent(query)}`
        ).toString()}`
      );
    },
    [i18n.language, history]
  );

  return (
    <div>
      <SubHeader title={t("labels.search")} />
      <div className="max-w-screen-xl mx-auto mt-12 px-4">
        <Search
          className="font-transat block mx-auto main-page-search-container"
          inputClassName="w-full bg-white main-page"
          searchQuery={initialSearchQuery}
          onSearch={onSearch}
        />
      </div>
    </div>
  );
};

export default SearchPage;
