import PropTypes from "prop-types";
import React, { ReactNode, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import gridIcon from "../../assets/grid-icon.svg";
import listIcon from "../../assets/list-icon.svg";
import canpccLogo from "../../assets/logos/canpcc_main.png";
import { useSearchQuery } from "../../lib/custom_hooks";
import GuideSection from "../common/GuideSection";
import Search from "../common/Search";
import Footer from "../layouts/Footer";
import OurPageInNumbers from "../statistics/OurPageInNumbers";

interface ILinkToSubpage {
  text: string;
  to: string;
  className?: string;
}

const LinkToSubpage = ({ text, to, className }: ILinkToSubpage) => (
  <Link className={`${className ?? ""} p-3 text-center block w-full relative`} to={to}>
    {text}
  </Link>
);

LinkToSubpage.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LinkToSubpage.defaultProps = {
  className: "",
};

const MainPage = () => {
  const { t, i18n } = useTranslation("");

  return (
    <div className="main-page-container overflow-hidden relative flex flex-col">
      <MainPageTopBar />
      <OurPageInNumbers />
      <div id="what-can-you-do-on-this-page">
        <h1 className="pt-24 pb-16 text-center roboto-condensed text-3xl">
          {t("labels.see_our_detailed_video")}
        </h1>
        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg mb-24 video-player">
          <iframe width="100%" loading="lazy" src={i18n.language === "fr" ? "https://www.youtube-nocookie.com/embed/by5Py4BEHwE?si=5IQ9lyL-_6zIKhw1" : "https://www.youtube-nocookie.com/embed/rG6F1NXIiAU?si=rtgwKfFtgEkQJeb-"} title="RecMaps" className="mx-auto"></iframe>
        </div>

        <div className="flex flex-col md:flex-row mx-auto items-top max-w-screen-lg">
          <GuideSection sectionKey="search">{t("guideSections.search")}</GuideSection>
          <GuideSection sectionKey="list">
            <Trans i18nKey="guideSections.list">
              Check out the{" "}
              <Link className="text-red-200 underline hover:opacity-50" to="/recommendations">
                full list of CAN-PCC recommendations
              </Link>{" "}
              and filter by key variables
            </Trans>
          </GuideSection>
          <GuideSection sectionKey="grid">
            <Trans i18nKey="guideSections.grid">
              Check out the{" "}
              <Link className="text-red-200 underline hover:opacity-50" to="/grid">
                living map of recommendations
              </Link>{" "}
              by Population and Interventions and filter by key variables
            </Trans>
          </GuideSection>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const MainPageTopBar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { i18n } = useTranslation();
  const initialSearchQuery = useSearchQuery();

  const onSearch = useCallback(
    (query) => {
      history.push(
        `/recommendations?${new URLSearchParams(
          `lng=${i18n.language}&searchQuery=${encodeURIComponent(query)}`
        ).toString()}`
      );
    },
    [i18n.language, history]
  );

  return (
    <div className="main-page-container__top flex flex-col flex-grow  ">
      <div className="mx-auto mt-16 mb-8 p-2 flex flex-col md:flex-row items-center">
        <img className="w-32 mb-8 md:mb-0" src={canpccLogo} alt="COVID19 logo" />
        <div className="md:ml-16">
          <div className="app-subtitle text-3xl font-transat">{t("app.mainPageSubtitle")}</div>
          <Search
            className="font-transat block mx-auto main-page-search-container"
            inputClassName="w-full bg-white main-page"
            searchQuery={initialSearchQuery}
            onSearch={onSearch}
          />
          <a
            className="text-red-200 pt-2 hover:underline block"
            href="#what-can-you-do-on-this-page"
          >
            {t("labels.learn_more_recmaps")}
          </a>
        </div>
      </div>
      <div className="block md:hidden px-8">
        <LinkToSubpage
          className="w-full rounded-lg2 bg-red-200 text-white bold hover:text-black hover:bg-red-300"
          to="/plain-language-recommendations"
          text={t(`labels.plainLanguageRecommendations`)}
        />
      </div>
      <div className="mx-auto flex flex-col align-center max-w-screen-lg px-8">
        <div className="text-3xl text-center text-red font-medium mb-2 mt-8">{t("app.main_page_header.title")}</div>
        <div className="text-xl2 font-medium">{t("app.main_page_header.description")}</div>
      </div>
      <Tiles>
        <Tile
          icon={gridIcon}
          className="tailwind-hidden lg:block"
          title={t("app.main_page_tiles.grid_title")}
        >
          <div className="mb-2 flex-grow">{t("app.main_page_tiles.grid")}</div>
          <LinkToSubpage
            className="w-full rounded-lg2 bg-red-300 bold hover:text-white hover:bg-gray-400"
            to="/grid"
            text={t(`labels.recommendations_map`)}
          />
        </Tile>
        <Tile icon={listIcon} title={t("app.main_page_tiles.list_of_recommendations_title")}>
          <div className="mb-2 flex-grow">{t("app.main_page_tiles.list_of_recommendations")}</div>
          <LinkToSubpage
            className="w-full rounded-lg2 bg-red-300 bold hover:text-white hover:bg-gray-400"
            to="/recommendations"
            text={t(`labels.recommendations_list`)}
          />
        </Tile>
        <Tile icon={listIcon} title={t("app.main_page_tiles.list_of_plrs_title")}>
          <div className="mb-2 flex-grow">{t("app.main_page_tiles.list_of_plrs")}</div>
          <LinkToSubpage
            className="w-full rounded-lg2 bg-red-300 bold hover:text-white hover:bg-gray-400"
            to="/plain-language-recommendations"
            text={t(`labels.plainLanguageRecommendations`)}
          />
        </Tile>
      </Tiles>
    </div>
  );
};

interface ITiles {
  children: ReactNode;
}

const Tiles = ({ children }: ITiles) => {
  return (
    <div className="tiles mt-10 md:flex md:flex-row mx-auto justify-center items-stretch flex-wrap">
      {children}
    </div>
  );
};

interface ITile {
  children: ReactNode;
  icon?: string;
  title?: string;
  className?: string;
  childrenClassName?: string;
}

const Tile = ({ title, icon, children, className, childrenClassName }: ITile) => {
  return (
    <div className={`mx-4 tile self-stretch ${className ?? ""}`}>
      <div className="mb-6 md:mb-4 md:w-auto w-full h-full flex flex-col max-w-sm">
        {icon && title && (
          <div className="flex flex-row items-center w-full mb-6">
            <img className="w-20 block" src={icon} alt={title} />
            <div className="text-xl2 font-medium ml-5">{title}</div>
          </div>
        )}
        <div className={`flex flex-col roboto flex-grow ${childrenClassName ?? ""}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
